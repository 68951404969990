import { useEffect } from 'react';
import nookies from 'nookies';
import Loader from 'components/LandingPage/Loader.jsx';
import PageMeta from 'components/PageMeta';
import getContentData from 'data/contentstack/get-content-data';
import { getSegmentedLandingPageContent } from 'data/contentstack/get-segmented-landing-page-content';
import getFeaturedProducts from 'data/featured-products';
import { getDiscountTypeMapByStore } from 'data/product-discounts';
import universalHydration from 'data/universal-hydration';
import useGetSegmentedLandingPage from 'hooks/useGetSegmentedLandingPage';
import sspMiddleware from 'middleware/gssp';
import setCaching from 'utils/cache-control';
import getCanonicalUrl from 'utils/get-canonical-url';
import getCorrelationId from 'utils/get-correlation-id';
import { sendPageView, setMetricsData } from 'utils/metrics';

const HOME_PAGE_PATH = '/home-page';
const HOME_PAGES_ID = 'home_pages';

export default function HomePage({
  canonical,
  featuredItems,
  location,
  menu,
  pageData: propPageData,
  productDiscountMap,
}) {
  const { pageData } = useGetSegmentedLandingPage({
    data: propPageData,
    location,
    pageId: HOME_PAGES_ID,
  });
  const { component_list: components = [], seo = {} } = pageData;
  const { meta_title: title, meta_description: description, keywords } = seo;

  useEffect(() => {
    setMetricsData({
      location,
      menu,
      pageType: 'home_page',
      title,
    });
    sendPageView(title);
  }, [title, location, menu]);

  return (
    <>
      <PageMeta
        canonical={canonical}
        description={description}
        keywords={keywords}
        title={title}
      />
      {components.map((component) => {
        const Component = Loader(component._content_type_uid);

        if (!Component) return null;

        return (
          <Component
            key={component.uid}
            ssrStore={location}
            ssrMenu={menu}
            featuredItems={featuredItems}
            productDiscountMap={productDiscountMap}
            {...component}
          />
        );
      })}
    </>
  );
}

export const getServerSideProps = sspMiddleware((store) => async (ctx) => {
  setCaching('short', ctx.res);

  const { log } = ctx.req;
  const correlationId = getCorrelationId({ request: ctx.req });

  const { store: locationFromState, menu = 'recreational' } = nookies.get(ctx);

  const location = locationFromState ?? '';

  const [universal, homePageData, pageData, featuredProducts, discountTypeMap] =
    await Promise.allSettled([
      universalHydration({ ctx, store, correlationId, log }),
      getContentData({ id: 'home_page_content', correlationId, log }),
      getSegmentedLandingPageContent({
        cmsUrlParam: HOME_PAGE_PATH,
        correlationId,
        id: HOME_PAGES_ID,
        location,
        log,
      }),
      getFeaturedProducts({
        categories: [
          'eighths',
          '14-grams',
          'ounces',
          'pre-rolls',
          'rip',
          '510-thread',
          'gummies',
          'concentrates',
        ],
        correlationId,
        log,
        menu: menu === 'medical' ? 'MEDICAL' : 'ADULT',
        store: location,
      }),
      getDiscountTypeMapByStore({ correlationId, log, store: location }),
    ]);

  const props = {
    canonical: getCanonicalUrl(''),
    featuredItems: [],
    location,
    menu,
  };

  if (pageData.status !== 'fulfilled' || !pageData.value) {
    return {
      notFound: true,
    };
  }

  if (universal.status === 'fulfilled') {
    props.footer = universal.value.footer;
    props.header = universal.value.header;
  }

  if (homePageData.status === 'fulfilled') {
    const jsonld = homePageData.value?.data[0].json_ld;
    props.jsonld = jsonld || '';
  }

  if (featuredProducts.status === 'fulfilled') {
    props.featuredItems = featuredProducts.value;
  }

  if (discountTypeMap.status === 'fulfilled') {
    props.productDiscountMap = discountTypeMap.value ?? {};
  }

  if (pageData.status === 'fulfilled') {
    props.pageData = pageData.value;
  }

  if (!props.pageData?.component_list) {
    return {
      notFound: true,
    };
  }

  props.metrics = {
    title: props.pageData?.seo?.meta_title ?? 'Home Page',
    pageType: 'home_page',
    location,
    menu,
  };

  return {
    props,
  };
});
