import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import logger from 'config/logger';
import { RequestInstance } from 'request';
import setIdleInterval from 'utils/set-idle-interval';

async function getSegmentedPageData({ cmsPageId, cmsUrlParam, location }) {
  try {
    const landingPageData = await RequestInstance.post(
      'api/content/segmented-landing-page',
      {
        json: {
          cmsPageId,
          cmsUrlParam,
          location,
        },
      }
    );
    return landingPageData ?? {};
  } catch (err) {
    logger.error(
      err,
      `Error retrieving segmented layout page components for url:${cmsUrlParam} location:${location}`
    );
    return [];
  }
}

function useGetSegmentedLandingPage({ data, location, pageId }) {
  const storeSelection = useSelector((state) => state.storeSelection);
  const [pageData, setPageData] = useState(data);
  const storedLocation = useRef(location);

  useEffect(() => {
    const get = () => {
      getSegmentedPageData({
        cmsPageId: pageId,
        cmsUrlParam: pageData.url,
        location: storeSelection,
      }).then((pd) => {
        setPageData(pd);
      });
    };

    if (storeSelection && storeSelection !== storedLocation.current) {
      storedLocation.current = storeSelection;
      get();
    }
    // refresh every hour
    const cleanup = setIdleInterval(() => {
      get();
    }, 3_600_000);

    return () => {
      cleanup();
    };
  }, [storeSelection]);

  useEffect(() => {
    setPageData(data);
  }, [data]);

  return { pageData };
}
export default useGetSegmentedLandingPage;
