import dynamic from 'next/dynamic';

const moduleByComponentId = {
  /* eslint-disable camelcase */
  landing_page_banner: dynamic(() => import('./Mega')),
  press_mentions: dynamic(() => import('./PressMention')),
  newsletter: dynamic(() => import('./EmailCapture')),
  email_capture: dynamic(() => import('./EmailCapture')),
  content_2_up: dynamic(() => import('./Content2Up')),
  youtube_video_player: dynamic(() => import('./YouTubePlayer')),
  text_block: dynamic(() => import('./TextBlock')),
  full_width_video_block: dynamic(() => import('./FullWidthVideo')),
  content_4_up_image_block: dynamic(() => import('./Image4UpBlock')),
  product_labels: dynamic(() => import('./FeaturedItems')),
  carousel: dynamic(() => import('./Carousel')),
  content_11_grid: dynamic(() => import('./Content11Grid')),
  content_grid: dynamic(() => import('./ContentGrid')),
  discount_block: dynamic(() => import('./DiscountBlock')),
  expandable_list: dynamic(() => import('./ExpandableList')),
  animated_content_2_up: dynamic(() => import('./AnimatedContent2Up')),
  location_grid: dynamic(() => import('./LocationGrid')),
  /* eslint-enable camelcase */
};

function Loader(componentId) {
  if (!moduleByComponentId[componentId]) return null;

  return moduleByComponentId[componentId];
}

export default Loader;
